import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, filter, map, of, shareReplay, switchMap } from 'rxjs';

import NavigationItem from '../common/models/navigation-item.model';
import { UserRoleService } from './user-role.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];
  public currentUrl = new BehaviorSubject<string>(undefined!);

  constructor(
    private router: Router,
    private location: Location,
    private userRoleService: UserRoleService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => this.currentUrl.next((event as NavigationEnd).urlAfterRedirects)),
      shareReplay(1),
    );
  }

  public back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  public getNavigation(): Observable<NavigationItem[]> {
    return this.userRoleService.isAdmin().pipe(
      switchMap((isAdmin: boolean) => {
        const menu: NavigationItem[] = [];

        menu.push({
          displayName: 'Toonbankverkoop',
          iconName: 'shopping_cart',
          route: 'counter-sales',
        } as NavigationItem);

        menu.push({
          displayName: 'Artikels',
          iconName: 'description',
          route: 'articles',
        } as NavigationItem);

        if (!isAdmin) {
          menu.push({
            displayName: 'Voorstellen',
            iconName: 'store',
            route: 'purchases/propositions',
          } as NavigationItem);
        }

        if (isAdmin) {
          menu.push({
            displayName: 'Kortingsgroepen',
            iconName: 'euro',
            route: 'article-groups',
          } as NavigationItem);

          // menu.push({
          //   displayName: 'Kortingen',
          //   iconName: 'euro_symbol',
          //   route: 'prices',
          // } as NavigationItem);

          menu.push({
            children: [
              {
                displayName: 'Orders',
                iconName: 'shopping_cart',
                route: 'purchases/orders',
              } as NavigationItem,
              {
                displayName: 'Voorstellen',
                iconName: 'store',
                route: 'purchases/propositions',
              } as NavigationItem,
            ],
            displayName: 'Bestellingen',
            iconName: 'sell',
            route: 'purchases',
          } as NavigationItem);

          menu.push({
            children: [
              {
                displayName: 'Backorders',
                iconName: 'shopping_cart',
                route: 'sales/orders',
              } as NavigationItem,
              {
                displayName: 'Zendnota',
                iconName: 'local_shipping',
                route: 'sales/delivery-notes',
              } as NavigationItem,
              {
                displayName: 'Facturen',
                iconName: 'payments',
                route: 'sales/invoices',
              } as NavigationItem,
              {
                displayName: 'Kredietnota',
                iconName: 'send_money',
                route: 'sales/credit-notes',
              } as NavigationItem,
            ],
            displayName: 'Verkoopsbeheer',
            iconName: 'sell',
            route: 'sales',
          } as NavigationItem);

          menu.push({
            displayName: 'Leveranciers',
            iconName: 'group',
            route: 'suppliers',
          } as NavigationItem);

          menu.push({
            displayName: 'Klantengroepen',
            iconName: 'groups',
            route: 'customer-groups',
          } as NavigationItem);

          menu.push({
            displayName: 'Klanten',
            iconName: 'group',
            route: 'customers',
          } as NavigationItem);

          menu.push({
            displayName: 'Bedrijf',
            iconName: 'work',
            route: 'company',
          } as NavigationItem);

          // menu.push({
          //   displayName: 'Gebruikers',
          //   iconName: 'manage_accounts',
          //   route: 'users',
          // } as NavigationItem);
        }

        return of(menu);
      }),
    );
  }
}
