@if ((breadcrumbs$ | async)?.length ?? 0 > 0) {
<ul>
  @for (breadcrumb of breadcrumbs$ | async; track breadcrumb; let i = $index) { @if (i !== 0) {
  <li class="breadcrumb-divider">></li>
  }
  <li>
    <ingenix-breadcrumb-item
      [label]="breadcrumb.label"
      [link]="breadcrumb.url"
      [exactUrl]="i !== ((breadcrumbs$ | async)?.length ?? 0) - 1"
    ></ingenix-breadcrumb-item>
  </li>
  }
</ul>
}
