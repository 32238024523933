<mat-sidenav-container class="container">
  <mat-sidenav #sidenav class="navigation" [mode]="mode" [(opened)]="opened">
    <section class="branding">
      <img class="branding__image" src="../../../assets/logo/logo.svg" alt="Auto service logo" />
    </section>
    <mat-nav-list>
      @for (navigationItem of navigation$ | async; track identify($index)) {
      <ingenix-navigation-item [navigationItem]="navigationItem"></ingenix-navigation-item>
      }
    </mat-nav-list>
  </mat-sidenav>
  <main class="container__content">
    <ingenix-breadcrumb></ingenix-breadcrumb>
    <router-outlet></router-outlet>
  </main>
</mat-sidenav-container>
