<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<a
  mat-list-item
  class="navigation-item"
  [ngClass]="{
    'navigation-item--active': navigationItem!.route ? router.isActive(navigationItem!.route, true) : false,
    expanded: expanded
  }"
  (click)="onItemSelect()"
>
  <section class="navigation-item__wrapper">
    <mat-icon class="navigation-item__icon">{{ navigationItem!.iconName }}</mat-icon>
    <span class="navigation-item__text">{{ navigationItem!.displayName.toUpperCase() }}</span>
  </section>
</a>
@if (expanded) {
<div class="navigation-item__expanded">
  @for (child of navigationItem!.children; track child) {
  <ingenix-navigation-item [navigationItem]="child"></ingenix-navigation-item>
  }
</div>
}
