import { Routes } from '@angular/router';
import { authGuardFn } from '@auth0/auth0-angular';

import { AdministratorGuard } from './guards/administrator-guard';

export const autoServiceRoutes: Routes = [
  {
    canActivate: [authGuardFn],
    children: [
      {
        data: { breadcrumb: 'Artikelbeheer' },
        loadChildren: () => import('../modules/articles/article.routing').then(r => r.articleRoutes),
        path: 'articles',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Artikelgroepbeheer' },
        loadChildren: () => import('../modules/article-groups/article-group.routing').then(r => r.articleGroupRoutes),
        path: 'article-groups',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Prijsbeheer' },
        loadChildren: () => import('../modules/prices/prices.routing').then(r => r.priceRoutes),
        path: 'prices',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Leveranciersbeheer' },
        loadChildren: () => import('../modules/suppliers/suppliers.routing').then(r => r.SupplierRoutes),
        path: 'suppliers',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Klantbeheer' },
        loadChildren: () => import('../modules/customers/customers.routing').then(r => r.CustomerRoutes),
        path: 'customers',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Gebruikersbeheer' },
        loadChildren: () => import('../modules/users/users.routing').then(r => r.UserRoutes),
        path: 'users',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Verkoopsbeheer' },
        loadChildren: () => import('../modules/sales/sales.routing').then(r => r.salesRoutes),
        path: 'sales',
      },
      {
        data: { breadcrumb: 'Aankoopbeheer' },
        loadChildren: () => import('../modules/purchases/purchase.routing').then(r => r.purchaseRoutes),
        path: 'purchases',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Bedrijfsbeheer' },
        loadChildren: () => import('../modules/companies/company.routing').then(r => r.CompanyRoutes),
        path: 'company',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Klantengroep' },
        loadChildren: () => import('../modules/relation-groups/relation-group.routing').then(r => r.relationGroupRoutes),
        path: 'customer-groups',
      },
      {
        data: { breadcrumb: 'Toonbankverkoop' },
        loadChildren: () => import('../modules/counter-sales/counter-sales.routing').then(r => r.CounterSalesRoutes),
        path: 'counter-sales',
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'counter-sales',
      },
    ],
    data: { breadcrumb: 'Home' },
    path: '',
  },
];
