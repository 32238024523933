import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { Router, RouterModule } from '@angular/router';
import NavigationItem from 'src/app/common/models/navigation-item.model';

@Component({
  imports: [CommonModule, MatIconModule, MatListModule, RouterModule],
  selector: 'ingenix-navigation-item',
  standalone: true,
  styleUrls: ['./navigation-item.component.scss'],
  templateUrl: './navigation-item.component.html',
})
export class NavigationComponent {
  public expanded = false;

  @Input()
  public navigationItem!: NavigationItem;

  constructor(public readonly router: Router) {}
  public onItemSelect() {
    this.router.navigate([this.navigationItem.route]);

    if (this.navigationItem.children && this.navigationItem.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
