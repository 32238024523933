import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import NavigationItem from 'src/app/common/models/navigation-item.model';
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';
import { NavigationComponent } from 'src/app/components/navigation-item/navigation-item.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';

@Component({
  imports: [CommonModule, RouterOutlet, BreadcrumbComponent, NavigationComponent, MatSidenavModule, MatListModule],
  selector: 'ingenix-root',
  standalone: true,
  styleUrls: ['./auto-service.container.scss'],
  templateUrl: './auto-service.container.html',
})
export class AutoServiceContainer implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public isMobile = false;
  public mode: MatDrawerMode = 'over';
  public navigation$!: Observable<NavigationItem[]>;
  public opened = false;
  public showMobileBackdrop = false;

  constructor(
    private _navigationService: NavigationService,
    private _responsiveLayoutService: ResponsiveLayoutService,
  ) {
    this.updateMenu();
  }

  public identify(index: number): number {
    return index;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnInit(): void {
    this.navigation$ = this._navigationService.getNavigation().pipe(takeUntil(this.destroy$));
  }

  public onItemClicked(event: boolean): void {
    if (this.isMobile) {
      this.opened = !event;
    }
    this.showMobileBackdrop = this.isMobile && this.opened;
  }

  @HostListener('window:resize', [])
  public updateMenu() {
    this.isMobile = this._responsiveLayoutService.isMobile();

    if (!this.isMobile) {
      this.mode = 'side';
    } else {
      this.mode = 'over';
    }
    this.opened = !this.isMobile;
    this.showMobileBackdrop = this.opened && this.isMobile;
  }
}
